.link {
    display: flex;
    position: relative;
    padding: 1em;
    width: auto;
    height: 100%;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.link--badge {
    width: 64px;
    height: 64px;
}

.link:hover {
    background-color: rgb(17, 64, 65);
}